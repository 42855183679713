*,
*::before,
*::after {
    box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
figure,
blockquote,
dl,
dd {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

ul,
ol {
    list-style: none;
}

body {
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
}

:focus {
    outline: none;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
}

.loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #333;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }