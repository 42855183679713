.accountBilling {
    padding: calc(2.85rem + 1.8vw) 0px;
    position: relative;
    overflow: hidden;
    .container{
        position: relative;
        &:after{
            content: "";
            background: url(../images/sectionBG.png);
            display: inline-block;
            width: 867px;
            height: 559px;
            position: absolute;
            left: inherit;
            top: 264px;
            z-index: 0;
            background-repeat: no-repeat;
            right: -249px;
            background-size: 100%;
        }
        .row{
            position: relative;
            z-index: 1;
        }
    }
}
.billingScreens {
    padding-bottom: 10rem;
    position: relative;
    overflow: hidden;
    .container{
        position: relative;
        &:after{
            content: "";
            background: url(../images/sectionBG.png);
            display: inline-block;
            width: 867px;
            height: 559px;
            position: absolute;
            left: inherit;
            top: calc(50% - 209px);
            z-index: 0;
            background-repeat: no-repeat;
            right: -329px;
            background-size: 90%;
            @media only screen and (max-width: 991px) {
                display: none;
            }
        }
        .row{
            position: relative;
            z-index: 1;
        }
    }
    .col-md-12{
        >h1 {
            padding: 0px;
            margin: 3rem 0 2rem 0;
            @media only screen and (min-width: 992px) {
                margin: 3.5rem 0 2.5rem 0;
            }
        }
    }

}
ul.subTotal {
    padding: 0px;
    margin-top: 20px;
    li {
        list-style: none;
        border-bottom: 1px solid #E0E0E0;
        padding: 5px 0px;
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
}

.subTotaldiv {
    border: 1px solid rgb(189 189 189 / 50%);
    border-radius: 5px;
    padding: 23px;
    margin-top: 20px;
    .subTotalitem {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        &:last-child {
            border-top: 1px solid rgb(189 189 189 / 50%);
            padding-top: 16px;
            margin-top: 16px;
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 0px;
        }
    }
}

.trailEnded {
    border: 1px solid rgb(189 189 189 / 50%);
    border-radius: 5px;
    // box-shadow: 0px 1px 2px 0px rgb(60 64 67 / 15%), 0px 2px 6px 2px rgb(60 64 67 / 0%);
    &.signleBlock{
        padding: 5rem 3rem !important;
    }
    p {
        font-size: calc(.75rem + 0.15vw);
        line-height: calc(1rem + 0.81vw);
        color: #666;
    }
    h5{
        font-weight: bold;
        color: #120E1C;
    }
    h6 {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        margin: 0px;
        button {
            background: no-repeat;
            border: none;
            font-size: 16px;
            font-weight: 500;
            color: #007AFF;
        }
    }
    .reachUs{
        p{
            color: #666666;
        }
    }
}

ul.paymentMethod {
    padding: 0px;
    margin: 20px 0px 0px 0px;
    li {
        list-style: none;
        display: flex;
        padding: .8rem 0;
        border-bottom: 1px solid rgb(189 189 189 / 50%);
        position: relative;
        &:last-child{
            border: none;
        }
        .labelradio {
            width: 100%;
            display: flex;
            @media only screen and (max-width: 992px) {
            align-items: center;
            }
            input[type="radio"] {
                opacity: 0;
                + label{
                    width: auto;
                    &:after {
                        content: "";
                        position: absolute;
                        left: 0px;
                        top: 17px;
                        width: 1.25em;
                        height: 1.25em;
                        display: block;
                        border: 1px solid #8A8A8F;
                        border-radius: 100px;
                        z-index: 0;
                        @media only screen and (max-width: 992px) {
                            top: 25px;
                            }
                    }
                }
                &:checked + label{
                    &:after {
                        content: "";
                        background: url(../images/paymentCheck.png);
                        position: absolute;
                        left: 0px;
                        top: 17px;
                        width: 1.25em;
                        height: 1.25em;
                        display: block;
                        border: none;
                        border-radius: 100px;
                        background-size: cover;
                        @media only screen and (max-width: 992px) {
                            top: 25px;
                            }
                    }
                }
            }
            span{
                padding:0 .5rem;
                color: #120E1C;
                &.cardExp {
                    color: #666665;
                }
                &.cardRadio{
                    padding-left: 0px;
                    padding-right: 1.5rem;
                    input.radio-billing {
                        position: relative;
                        z-index: 2;
                        width: 1.25em;
                        height:1.25em;
                        top: 4px;
                        cursor: pointer;
                    }
                }
                &.delCard{
                    position: absolute;
                    right: 0px;
                }
                button{
                    border: none;
                    padding: 0px;
                    background: transparent;
                }

            }
            .cardListExp {
                display: initial;
                @media only screen and (max-width: 992px) {
                    span.cardExp {
                        display: block;
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

.confirmModel {
    .modal-content {
        padding: 24px;
        .modal-header {
            border: none;
            padding: 0px;
            position: relative;
            button.btn-close {
                position: absolute;
                right: 0px;
                top: 0px;
                &:focus{
                    box-shadow: none;
                }
            }
            h4.modal-title {
                padding-top: 26px;
                padding-bottom: 5px;
                font-size: 20px;
                color: #120E1C;
            
            }
        }
        .modal-body {
            padding: 0px;
            border: none;
            font-size: 14px;
            color: #666666;
            line-height: normal;
        }
        .modal-footer {
            border: none;
            padding: 30px 0px 0px 0px;
            button.btn.btn-secondary {
                background: no-repeat;
                border: none;
                font-size: 16px;
                font-weight: 500;
                color: #007AFF;
                margin-right: 0;
            }
            button.btn.btn-primary {
                font-size: calc(.66rem + .4vw) !important;
                padding: 11px 32px !important;
                border-radius: 6px !important;
            }
        }
    }
}

@keyframes tick {
    to {transform: rotate(360deg);}
}

.pageLoader {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    top: 0px;
    left: 0px;
    z-index: 2;
    .loaderClock{
        position: absolute;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        border: 3px solid #f9f9f9;
        right: 0px;
        left: 0px;
        top: 0px;
        bottom: 0px;
        margin: auto;
    &:after {
        content: "";
        position: absolute;
        bottom: 29px;
        left: 30px;
        display: block;
        transform-origin: 0% 100%;
        width: 2px;
        height: 24px;
        background: #f9f9f9;
        animation: tick 1s infinite linear;
    }
    }
    .lds-grid {
        display: inline-block;
        position: absolute;
        width: 80px;
        height: 80px;
        left: 0px;
        right: 0px;
        margin: auto;
        top: 0px;
        bottom: 0px;
      }
      .lds-grid div {
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #0183FF;
        animation: lds-grid 1.2s linear infinite;
      }
      .lds-grid div:nth-child(1) {
        top: 8px;
        left: 8px;
        animation-delay: 0s;
      }
      .lds-grid div:nth-child(2) {
        top: 8px;
        left: 32px;
        animation-delay: -0.4s;
      }
      .lds-grid div:nth-child(3) {
        top: 8px;
        left: 56px;
        animation-delay: -0.8s;
      }
      .lds-grid div:nth-child(4) {
        top: 32px;
        left: 8px;
        animation-delay: -0.4s;
      }
      .lds-grid div:nth-child(5) {
        top: 32px;
        left: 32px;
        animation-delay: -0.8s;
      }
      .lds-grid div:nth-child(6) {
        top: 32px;
        left: 56px;
        animation-delay: -1.2s;
      }
      .lds-grid div:nth-child(7) {
        top: 56px;
        left: 8px;
        animation-delay: -0.8s;
      }
      .lds-grid div:nth-child(8) {
        top: 56px;
        left: 32px;
        animation-delay: -1.2s;
      }
      .lds-grid div:nth-child(9) {
        top: 56px;
        left: 56px;
        animation-delay: -1.6s;
      }
      @keyframes lds-grid {
        0%, 100% {
          opacity: 1;
        }
        50% {
          opacity: 0.5;
        }
      }
      
}
p.modalP {
    font-size: 1rem;
    margin-bottom: 1rem;
}
.stripeFormin {
    border: 1px solid #dbdbdb;
    padding: 10px 0px 10px 10px;
    border-radius: 5px;
    &:hover {
        border-color: #0d6efd;
    }
}
.trialDays, .trialIncludes  {
    padding: 1.5rem;
    border: 0.5px solid rgb(189 189 189 / 50%);
    border-radius: 5px;
    height: 100%;
    .progress {
        margin: 30px 0px 15px 0px;
        --bs-progress-height: 0.625rem;
        .progress-bar {
            background-color: #34C759;
        }
    }

    h5{
        font-size: 16px;
        color: #120E1C;
        font-weight: 600;
        margin: 0px;
    }
    p{
        font-size: 14px;
        color: #666666;
        &:last-child{
            color: #120E1C ;
        }
    }
    ul{
        padding:  1.5rem 0px 0px 0px;
        margin: 0px;
        li{
            list-style: none;
            padding-left: 2rem;
            background: url(../images/checkListgreen.svg);
            background-repeat: no-repeat;
            background-position: left center;
            line-height: 2rem;
        }
    }
}
.itemPrice {
    color: #120E1C;
    span {
        color: #666666;
        margin-left: 0.5rem;
    }
}
.upgradeAccount {
    padding: 0px;
    margin: 0px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    margin-bottom: 12rem;
    overflow: hidden;
    .col-md-7, .col-md-5 {
        padding: 0px;
    }
    .upgradeDetails {
        padding: 2.5rem;
        p {
            padding: 0.5rem 0;
            color: #120E1C;
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }
    .anouncmentImg {
        text-align: right;
        img {
            max-height: 329px;
        }
    }
}

.fetureCompare {
    padding: 1.5rem 0rem 2rem 0;
    h5{
        display: flex;
        align-items: center;
        color: #120E1C;
        font-weight: 600;
        margin: 0px 10px 0px 0px;
        padding: 0px 10px 0px 0px;
        border-right: 1px solid rgb(130 130 130 / 64%);
        &:last-child{
            border: none;
        }
        span{
            font-size: 14px;
            margin-left: 7px;
            font-weight: normal;
        }
    }
}

.billingScreens .col-md-12>h1 {
    margin: 3rem 0 2rem;
    font-size: 2rem;
}
.billingScreens {
    padding-bottom: 2rem;
}

p.modalP.cardDet {
    margin-top: 0.7rem;
    margin-bottom: 0.3rem;
}

.endTrailup {
    .trailEnded {
        border: none;
        padding: 1.5rem 0 !important;
         h6{
            display: block;
            span {
                display: none;
            }
            button {
                font-size: calc(0.66rem + 0.4vw) !important;
                padding: 11px 32px !important;
                border-radius: 6px !important;
                background-color: #0d6efd;
                color: #fff;
                &:hover{
                    background-color: #0b5ed7 !important;
                }
            }
         }
    }
}

.defultCardcheck {
    line-height: 1.2rem;
    input[type="checkbox"] {
        width: 1.25em;
        height: 1.25em;
        border: 1px solid #4F4F4F;
        float: left;
        margin-right: 0.7rem;
    }
    span {
        color: #120E1C;
        font-size: .87rem;
        font-weight: normal;
    }
}
.ctaMOB.loginLogout {
    .dropdown {
        .btn {
            margin-right: auto;
            padding: 0 !important;
            display: block;
            img.userPic {
                margin-right: 0px;
            }
            &:after {
              display: none;
            }
           span.userName {
            margin-top: 0.5rem;
            font-size: 4vmin !important;
            line-height: 5.5vmin;
            font-weight: 600;
            color: #fff;
            display: block;
            }
        }
        .dropdown-menu {
            right: auto !important;
            left: auto !important;
            margin: 0px auto;
            position: relative !important;
            top: 0px !important;
            width: auto !important;
            text-align: center;
            background: transparent !important;
            border: none;
            color: #fff !important;
            transform: inherit !important;
            @media only screen and (max-width: 1199px)  {
            height: 0px;
            }
            &.show{
                @media only screen and (max-width: 1199px)  {
                height: auto;
                }
            }
            &:after{
                display: none !important;
            }
            button.dropdown-item {
                color: #fff;
                display: inline-block;
                width: auto!important;
                font-size: 5vmin;
                line-height: 6.5vmin;
                font-weight: 600;
            }
        }
    }
}

section.billingScreens.bg-lightBlue {
    @media only screen and (min-width: 768px)  {
    padding-bottom: 11rem;
    }
}

div#root {
    min-height: 101vh;
}




.line {
    margin-bottom:6px;
    border-radius: 2px;
    background: rgba(130, 130, 130, 0.2);
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
    background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
    background-size: 800px 100px;
    animation: wave-lines 2s infinite ease-out;
    height: 10px !important;
}

@keyframes wave-lines {
   0% {
       background-position: -468px 0;
   }
    100% {
       background-position: 468px 0;
   }
}

.faildPaymentPop {
    .modal-dialog.modal-md {
        max-width: 450px;
        .trailEnded {
            padding: 0px !important;
            border: none;
            h6{
                margin-top: 20px;
                span{
                    display: none;
                }
                button{
                    color: #fff;
                    background-color: #007AFF;
                    border-color: #007AFF;
                    font-size: 16px !important;
                    padding: 11px 32px !important;
                    border-radius: 6px !important;
                }
            }
        }
    }
}