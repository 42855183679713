@import "./_variables.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
*input,
*button {
  outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.login {
  &-wrapper {
    display: flex;
    justify-content: center;
    padding: 12px;
    margin: 120px 0 294px 0;
    align-items: center;
    @media screen and (max-width: 1240px) {
      margin: 72px 0 72px 0;
    }
    > div {
      max-width: 552px;
      width: 100%;
    }
    .title {
      margin-bottom: 24px;
      padding-bottom: 0;
      text-align: center;
    }
  }
  &-button__country {
    @include border-bottom;
    max-width: 361px;
    width: 100%;
    margin: 0 auto;
    > button {
      padding: 0;
    }
    @include text18-400;
    position: relative;
    &:after {
      content: "";
      background: url(../images/login/arrow.svg);
      display: inline-block;
      width: 18px;
      height: 18px;
      position: absolute;
      right: 0;
      top: 18px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }
  &-button__index {
    border: none;
    @include border-bottom;
    max-width: 100px;
    width: 100%;
    padding: 18px 16px;
    @include text18-400;
  }
  &-button__phone {
    border: none;
    @include border-bottom;
    @include text18-400;
    width: 253px;
    padding: 18px 16px;
    &::placeholder {
      @include placeholder;
    }
  }
  &-modal-select {
    height: 432px;
    box-sizing: border-box;
    width: 100%;
    border: none;
    overflow: auto;
    > div {
      display: flex;
      height: 48px;
      align-items: center;
      align-self: stretch;
      padding: 0px 24px;
      cursor: pointer;
      &:hover {
        background: rgba(179, 215, 255, 0.45);
      }
    }
  }
  &-modal-country__img {
    width: 20px;
    height: 20px;
  }
  &-modal__destination {
    @include text14-400;
    color: $color-gray;
    padding-left: 8px;
  }
  &-button__wrapper-phone {
    max-width: 361px;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
    display: flex;
    gap: 8px;
    margin: auto;
  }
  &-modal-body {
    padding: 0;
    border-top: 1px solid #e0e0e0;
  }
  &-modal-input {
    border: none;
    &__close {
      background: transparent;
      border: none;
    }
  }
  &-modal__search {
    padding: 14px 24px;
  }
  &-modal__header {
    padding: 24px 24px 12px 24px;
    border-bottom: 0;
  }
}

.form-control:focus {
  box-shadow: none;
}
.input-group-text {
  border: none;
  background-color: transparent;
  padding: 0;
  margin-right: 10px;
}
.base-secondary {
  max-width: 361px;
  width: 100%;
  display: flex;
  padding: 14px 32px;
  justify-content: center;
  align-self: stretch;
  border-radius: 6px;
  background: rgba(31, 31, 31, 0.12);
}
.input-error {
  border-bottom: 2px solid $color-state-error;
}
.input-error-text {
  @include error-message;
}
.custom-option {
  padding-left: 30px;
  background-repeat: no-repeat;
  background-size: 20px;
}

.button-secondary {
  display: inline-flex;
  padding: 10px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  gap: 8px;
  color: $color-main;
}
.otp {
  &-input-code {
    max-width: 165px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }
  &-input {
    border: none;
    @include border-bottom;
    @include text18-400;
    width: 165px;
    padding: 18px 50px;

    &::placeholder {
      @include placeholder;
    }
  }
  &-input-button {
    padding: 0;
    margin-top: 32px;
  }
}
.phone-not-found {
  &-description {
    @include text14-400;
    color: $color-gray;
    margin-bottom: 40px;
    @media (max-width: map-get($grid-breakpoints, lg)) {
      margin-bottom: 16px;
    }
  }
  &-footer {
    display: flex;
    gap: 16px;
    align-items: flex-end;
    align-self: stretch;
    justify-content: flex-end;
    @media (max-width: map-get($grid-breakpoints, lg)) {
      display: inline-grid;
      justify-content: center;
      margin-top: 40px;
    }
    &__button {
      display: flex;
      padding: 10px 12px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }
  }
  &-container {
    width: 570px;
    @media (max-width: map-get($grid-breakpoints, lg)) {
      width: 100%;
    }
  }
}
