// colors
$color-gray-3: #828282;
$color-gray: #666666;
$color-gray-2: #E4E5E7;
$color-gray-4: #AFB1B6;
$color-gray-5: #F1F4F9;
$color-gray-6: #C9CBCF;
$color-gray-7: #555B66;
$color-text-quaternary: #c8c7cc;
$color-text-primary: #120e1c;
$color-main: #007aff;
$color-state-error: #eb5757;
$color-grey-label: #8a8a8f;
$color-blue: #004999;
$color-dark-blue: #001F40;
$color-dark-blue-2: #111827;
$color-white: #ffffff;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);
// mixins
@mixin border_bottom {
  border-radius: 0%;
  display: flex;
  padding: 18px 0px;
  align-items: center;
  border-bottom: 1px solid $color-gray-3;
  &:focus {
    border-bottom: 2px solid $color-main;
  }
}
@mixin placeholder {
  color: $color-text-quaternary;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  margin-left: 16px;
}
// mixin text
@mixin text18-400 {
  color: $color-text-primary;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}
@mixin text14-400 {
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
}
@mixin error-message {
  position: absolute;
  color: $color-state-error;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
}
