@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Poppins:wght@200;400;500;600;700;800&family=Quicksand:wght@300&display=swap");

body {
  font-family: "Poppins", sans-serif;
  overflow: hidden;
  overflow-y: auto;
}

@media only screen and (min-width: 1200px) {
  .sticky-top {
    position: fixed;
    top: 0;
    z-index: 1020;
    width: 100%;
  }
  div#root main {
     padding-top: 126px;
    &.call-records-page {
      padding-top: 0;
    }
  }
}

html {
  font-size: 100%;
  scroll-behavior: smooth;
}

.btn {
  font-size: calc(0.66rem + 0.4vw);
  padding: 11px 32px;
  border-radius: 6px;
}

button.btn {
  &:disabled {
    background: rgb(31 31 31 / 12%);
    color: #8a8a8f;
    border-color: rgb(31 31 31 / 12%);
  }

  &.btn-light {
    font-size: 16px;
    padding: 16px 32px;
    background: rgba(31, 31, 31, 0.12);
    border-color: rgba(31, 31, 31, 0.12);
    color: #8a8a8f;
    box-shadow: none;
    font-weight: 500;

    &:hover {
      background-color: #007aff;
      color: #fff;
      border-color: #007aff;
    }
  }
}

p {
  font-size: calc(1rem + 0.15vw);
  line-height: calc(1rem + 0.81vw);
  font-weight: 400;
  margin: 0px;
  padding: 0px;
  color: #120e1c;
}

.f-16 {
  font-size: calc(0.66rem + 0.4vw);
}

section.section figure {
  margin: 0px;
}

.h1,
h1 {
  font-size: calc(1rem + 2.34vw);
  line-height: calc(1rem + 3vw);
  font-weight: 600;
  padding: 0px 0px 32px 0px;
}

.h2,
h2 {
  font-size: calc(1rem + 1.75vw);
  line-height: calc(1rem + 2.35vw);
  font-weight: 600;
  padding: 0px 0px 32px 0px;
  color: #120e1c;
}

.h3,
h3 {
  font-size: calc(1rem + 1.17vw);
  line-height: calc(1rem + 1.65vw);
  font-weight: 600;
  color: #120e1c;
}

.h4,
h4 {
  font-size: calc(1rem + 0.59vw);
  line-height: calc(1rem + 0.95vw);
  font-weight: 600;
  color: #120e1c;
}

.pt-56 {
  padding-top: calc(2.3rem + 1.5vw);
}

footer.footer {
  background-color: #000;
  color: #fff;
  overflow: hidden;

  .footerNav {
    padding: 55px 0px;
    position: relative;
    z-index: 1;
  }

  .container {
    position: relative;
  }
}

.footer .container:before {
  content: "";
  background: url(../images/sectionBG.png);
  display: inline-block;
  width: 867px;
  height: 559px;
  position: absolute;
  left: calc(0px - 564px);
  z-index: 0;
  background-repeat: no-repeat;
  background-size: 95%;
  right: inherit;
  opacity: 0.05;
  background-position: left top -287px;
}

.bannerSec.bg-black {
  color: #fff;
  position: relative;
}

.bg-lightBlue {
  background-color: #f1f4f9;
}

.bg-blue {
  background-color: #0055b3;
  color: #fff;
}

.bg-blueDark {
  background-color: #cce4ff;
}

.bg-white {
  background-color: #fff;
}

.headerMain {
  background-color: #fff;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding: 45px 0px;
  border-bottom: 0.5px solid #bdbdbd;

  &.darkHeader {
    padding: 10px 0px;
  }
}

.menu-main-menu {
  align-items: center;
  justify-content: center;
  .menu-item {
    padding: 0px 16px;
  }
}

nav.navbar > .row {
  align-items: center;
}

#nav-toggle {
  position: relative;
  right: 0;
  top: 1px;
  height: 32px;
  width: 57px;
  padding-top: 0px;
  padding-bottom: 0px;
  display: block;
  background: transparent;
  border: none;
  padding: 0px;
  margin: 0px;
  width: auto;

  span {
    height: 2px;
    width: 24px;
    background: #000;
    position: absolute;
    display: block;
    content: "";
    transition: all 500ms ease-in-out;
    right: 0px;
    top: 15px;

    &:before,
    &:after {
      height: 2px;
      width: 24px;
      background: #000;
      position: absolute;
      display: block;
      content: "";
      transition: all 500ms ease-in-out;
    }

    &:before {
      top: -8px;
    }

    &:after {
      bottom: -8px;
    }
  }

  &.nav-open {
    span {
      &:after,
      &:before {
        background-color: #fff;
        transition: all 500ms ease-in-out;
      }
      &:before {
        transform: translateY(4px) rotate(45deg);
      }
      &:after {
        transform: translateY(-12px) rotate(-45deg);
      }
    }
  }
}

.headerMain {
  .container-fluid {
    padding: 0px 40px;
  }

  ul.navbar-nav li {
    &.freetrial_butn a {
        color: #fff;
        font-weight: 600;
    }

    a {
      text-decoration: none;
      position: relative;
      color: #000;
      font-size: calc(0.5rem + 0.6vw);
    }
  }
}

.footer-social-link ul {
  li {
    display: inline-block;

    a {
      font-size: 0px;
      background-color: rgb(255 255 255 / 10%);
      padding: 9px;
      width: 32px;
      height: 32px;
      margin: 0px 12px 0px 0px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      transition: all 0.5s;
    }
  }
}

.mobSocial.footer-social-link {
  text-align: center;
}

.footer-social-link ul li a:hover {
  span.svg-icon svg path {
    fill: #000;
    transition: all 0.3s ease-in-out;
  }

  background-color: #fff;
}

.aboutQara ul#menu-footer-menu li a:after,
.headerMain ul.menu-main-menu li:not(.freetrial_butn) a:after,
#menudesktop .menu-item:not(.freetrial_butn) a:after {
  content: "";
  position: absolute;
  width: 0px;
  height: 2px;
  left: 50%;
  bottom: -5px;
  background-color: #000;
  transition: all ease-in-out 0.2s;
}

.aboutQara ul#menu-footer-menu li a:hover:after,
#menudesktop .menu-item a:hover:after {
  width: 100%;
  left: 0;
}

@media (min-width: 768px) {
  #menudesktop .loginBtn {
    margin-right: 24px;
  }
}


.headerMain ul.menu-main-menu li {
  a:hover:after,
  &.current-menu-item a:after {
    width: 100%;
    left: 0;
  }
}

.aboutQara {
  a img {
    max-width: 55px;
    height: auto;
  }

  ul {
    padding: 20px 0px;
    margin: 0px;

    li {
      display: inline-block;
      margin-right: 32px;

      a {
        color: #fff;
      }
    }
  }
}

.getApp {
  display: flex;
  align-items: end;
  justify-content: end;

  .widget-container {
    margin-left: 24px;
  }

  div#media_image-4 {
    img.image {
      max-width: 81px;
      height: auto;
    }

    border: 1px solid #fff;
    border-radius: 8px;
    padding: 8px;
  }
}

.getApp h3 {
  font-size: calc(0.7rem + 0.5vw);
  line-height: normal;
  color: #fff;
}

.app-btn {
  .btn {
    border: 1px solid #fff;
    color: #fff;
    padding: 8px 17px;
    margin: 16px 0px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;

    svg {
      margin-right: 3px;
      path {
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
      }
    } 

    &:hover {
      color: #000;
      background-color: #fff;
      svg path {
        fill: #000;
      }
    }
  }

  p {
    color: #fff;
    opacity: 0.6;
    font-weight: 400;
    font-size: calc(0.52rem + 0.41vw);
    line-height: calc(0.8rem + 0.6vw);
    max-width: 140px;
  }
}

@media (max-width: 1440px) {
  .h1,
  h1 {
    font-size: calc(1rem + 2.22vw);
    line-height: calc(1rem + 3vw);
  }
}

@media (max-width: 1199px) {
  .headerMain ul.navbar-nav li a {
    font-size: 16px;
  }

  .mob2col {
    position: relative;
    z-index: 2;
  }

  .mob8col {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #000;
    z-index: -1;
    left: 0px;
    top: 0px;
    bottom: 0px;
    display: block;
    opacity: 0;
    visibility: hidden;
  }

  .headerMainmob .mob8col {
    opacity: 1;
    visibility: visible;
  }

  .mob8col #navbarMain {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    display: flex;
    align-items: end;
    opacity: 0;
    z-index: -1;
    height: 100%;
    visibility: hidden;
  }

  .headerMainmob .mob8col #navbarMain {
    opacity: 1;
    z-index: 1;
    visibility: visible;
  }

  .mobSocial {
    padding-bottom: 30px;
    transform: translatey(100%);
    transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .mob8col #navbarMain .mainMenudiv {
    transform: translatey(5%);
    transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .headerMainmob .mob8col {
    .mobSocial,
    #navbarMain .mainMenudiv {
      transform: translatey(0);
    }
  }

  .container-fluid,
  .container {
    padding-left: 32px;
    padding-right: 32px;
    width: 100%;
    max-width: 100%;
  }

  .headerTopBar {
    .d-flex.align-items-center {
      justify-content: space-between;
      width: 100%;
    }
  }

  #masthead {
    padding: 20px 0px;
  }

  .headerMain ul.menu-main-menu li {
    a {
      position: relative;
      color: #ffffff;
      font-size: 5vmin;
      line-height: 6.5vmin;
      font-weight: 600;
      display: inline-block;
    }

    padding: 2vh 0vh;
  }
}

@media (max-width: 1024px) {
  a.custom-logo-link {
    max-width: 60px;
    overflow: hidden;
  }

  .headerMain ul.menu-main-menu li a {
    font-size: 5vmin;
    line-height: 6.5vmin;
  }

  .f-16 {
    font-size: calc(1rem + 0vw);
    line-height: calc(1rem + 0.78vw);
  }

  .h2,
  h2 {
    font-size: calc(1rem + 1.57vw);
    line-height: calc(1rem + 2.1vw);
  }
}

@media (max-width: 992px) {
  .btn {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .footer-social-link ul li a {
    margin-bottom: 16px;
  }

  footer.footer .footerNav {
    padding: 30px 0px 15px 0px;
  }

  .getApp {
    flex-direction: column;

    .widget-container {
      margin-left: 0;
    }

    div#media_image-4 {
      margin: 20px 37px 0px 0px;
    }
  }
}

@media (max-width: 744px) {
  .h1,
  h1 {
    font-size: calc(1rem + 2.14vw);
    line-height: calc(1rem + 3vw);
  }

  .h2,
  h2 {
    font-size: calc(1rem + 1.1vw);
    line-height: calc(1rem + 1.65vw);
  }

  .f-16 {
    font-size: calc(0.88rem + 0vw);
    line-height: calc(1rem + 0.75vw);
  }

  .howWorkInner .workImg {
    padding-top: 24px;
  }
}

@media (max-width: 575px) {
  section.section figure img {
    max-width: 100%;
  }
  .container,
  .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }

  .footerNav .row .col.col-6 {
    width: 100%;

    .getApp {
      align-items: baseline;
      padding-top: 10px;

      #media_image-4 {
        display: none;
      }
    }
  }

  footer.footer .footerNav {
    padding: 30px 0px;
  }
}

@media (min-width: 1500px) {
  p {
    font-size: 18px;
    line-height: 27px;
  }
  .f-16 {
    font-size: 16px;
  }
  .h1,
  h1 {
    font-size: 48px;
    line-height: 57px;
  }
  .h2,
  h2 {
    font-size: 40px;
    line-height: 48px;
  }
  .h3,
  h3 {
    font-size: 32px;
    line-height: 38px;
  }
  .h4,
  h4 {
    font-size: 24px;
    line-height: 28px;
  }
  .pt-56 {
    padding-top: 56px;
  }
}

.loginLogout {
  .dropdown {
    text-align: right;

    .btn {
      background-color: transparent;
      border: none;
      color: #000;
      padding: 0 22px 0 0;
      position: relative;
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 17px;

      &:after {
        position: absolute;
        right: 0px;
        top: 15px;
      }

      img.userPic {
        margin-right: 10px;
      }

      span.userName {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 170px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: calc(0.66rem + 0.4vw);
      }

      &:focus,
      &:focus-visible {
        box-shadow: none;
        border: none;
      }
    }

    .dropdown-menu {
      position: absolute;
      padding: 0px;
      background: #f1f4f9;
      border: 0.5px solid #ebebeb;
      border-radius: 3px;
      display: block;
      box-shadow: 0px 2px 8px rgb(0 0 0 / 4%), 0px 1px 2px rgb(0 0 0 / 7%);
      z-index: 2;
      opacity: 0;
      pointer-events: none;
      transform: translateY(20px);
      right: 0px;
      left: inherit;
      font-size: 14px;

      button.dropdown-item {
        font-size: 0.95rem;
        padding: 12px 12px;

        &:hover {
          background-color: #b3d7ff;
        }
      }

      li {
        button.dropdown-item {
          font-size: 0.95rem;
          padding: 10px 12px;
        }
      }

      &.show {
        opacity: 1;
        pointer-events: auto;
        transform: translateY(0);
        top: 40px;

        &:before {
          position: absolute;
          top: -20px;
          right: 0;
          width: 100%;
          height: 20px;
          content: "";
          display: block;
          z-index: 1;
        }

        &:after {
          position: absolute;
          top: -15px;
          right: 10px;
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 15px solid #f1f4f9;
          content: "";
          display: block;
          z-index: 2;
          transition: all 200ms linear;
        }
      }
    }
  }
}

div#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  grid-template-rows: 1fr auto;
}

.ctaMOB {
  ul.mainLoginmenu {
    padding: 0px;
    margin: 0px;

    figure {
      margin: 0rem;

      span.userName {
        display: block;
        padding-top: 0.5rem;
        font-size: 4vmin;
        line-height: 5.5vmin;
      }
    }

    li {
      padding: 2vh 0vh;

      a {
        font-size: 5vmin;
        line-height: 6.5vmin;
        font-weight: 600;
        display: inline-block;
        color: #fff;
        text-decoration: none;

        &.btn.btn-primary {
          font-size: 3.5vmin;
          line-height: 5vmin;
          font-weight: 600;
        }
      }
    }
  }
}
